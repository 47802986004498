import React, {useEffect, useState} from 'react';
import {byDate, formatDate, getWeekLabel, isCurrentWeek} from "../../../utils/utils";

let timeout;

export const Week = ({
                         week,
                         weekKey,
                         renderEditDatum,
                         setEditing,
                         editing
                     }) => {
    const [expanded, setExpanded] = useState(isCurrentWeek(weekKey));
    const [open, setOpen] = useState(expanded);

    useEffect(() => {
        if (timeout) {
            clearTimeout(timeout);
        }
        if (expanded)
            timeout = setTimeout(() => {
                setOpen(expanded);
                timeout = undefined;
            }, 200);
        else
                setOpen(expanded);
    }, [expanded])

    if (!week) return <></>;
    const renderDatum = (datum) =>
        datum.id === editing ? renderEditDatum() : (
            <button
                key={datum.id}
                className="rounded thing datum"
                onClick={() => setEditing(datum.id)}
            >
                <span className="datum__date">
                {formatDate(datum.date, true)}:
                </span>
                <span className="datum__quantity">
                    {datum.quantity}
                </span>
            </button>
        )

    const renderExpansion = () => week.sort(byDate).map(renderDatum)
    const total = week.reduce((acc, val) => acc + val.quantity, 0);
    const weekLabel = getWeekLabel(weekKey);

    return (
        <div
            className={`rounded thing week ${expanded ? 'expanded' : ''}`}
        >
            <span
                className="header"
                onClick={() => setExpanded(!expanded)}
            >
                <span className="time">{weekLabel}:</span>
                <span className="week__quantity">{total}</span>
            </span>
            <div
                className={
                `expansionWrapper ${open ? 'open' : ''}`
            }
            >
                <div className="expansion">
                    {renderExpansion()}
                </div>
            </div>
        </div>
    );
}