import moment from "moment";


export const dayMap = [
    'Man',
    'Tir',
    'Ons',
    'Tors',
    'Fre',
    'Lør',
    'Søn'
]

export const monthMap = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Des'
]


export const dateFormat = "D. MMM YY";
export const dateFormatWithDay = "ddd D. MMM YY";

export const formatDate = (date, withDay) =>
    date.format(withDay ? dateFormatWithDay : dateFormat);

export const momentToId = date =>
    date.format('D_MMM_YY');

export const idToMoment = id =>
    moment(id.replaceAll('_', ' '));

export const prettyDate = (m) => {
    if (!m.isoWeekday)
        m = moment(m);
    const day = dayMap[m.isoWeekday() - 1];
    const date = m.format('D.')
    const month = monthMap[m.month()];
    let year = '';
    if (!m.isSame(moment(), 'year'))
        year = m.format(' YYYY');

    return `${day} ${date} ${month}${year}`
}

export const isCurrentWeek = weekKey =>  weekKey === getWeekId(moment());

export const getWeekId = (m) => {
    const moonth = m.format('MM');
    let weekNumber = m.format('WW');
    let year = m.format('YYYY');

    if (weekNumber === '01' && moonth === '12') {
        year = `${parseInt(year) + 1}`
    }

    if (weekNumber === '53') {
        year = parseInt(year) - 1;
    }

    return `${year}_${weekNumber}:${m.format('W')}`;
}

export const getWeekLabel = (weekKey, thisWeek) => {
    const year = weekKey.split('_')[0];
    const yearLabel = moment().format('YYYY') === year ? '' : ` ${year}`;
    return thisWeek && isCurrentWeek(weekKey) ? 'Denne uken' : `Uke ${weekKey.split(':')[1]}${yearLabel}`;
}

export const generateUuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : r & (0x3 | 0x8);
        return v.toString(16);
    });
};

export const byDate = (a, b) => {
    const aDate = moment(a.date);
    const bDate = moment(b.date);
    return aDate > bDate ? -1 :
        aDate < bDate ? 1 :
            0
}

export const by = foo => (a, b) =>  a[foo] > b[foo] ? -1 :
        a[foo] < b[foo] ? 1 :
            0;

export const copyToClipboard = text => {
    const el = document.createElement('textarea');
    el.value = JSON.stringify(text);
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}
