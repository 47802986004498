import React, {Fragment, useState} from 'react';
import 'react-dates/initialize';
import {BrowserRouter, Link, Route, Switch} from "react-router-dom";
import {Menu} from "./App/Menu/Menu";
import {FourOhFour} from "./App/General/FourOhFour";
import {NewCount} from "./App/Pages/NewCount/NewCount";
import {Count} from "./App/Pages/Count/Count";
import {Summary} from "./App/Pages/Summary/Summary";
import useEffectOnce from "./utils/UseEffectOnce";
import {firebaseGet} from "./App/FireBase/firebase";
import {Workout} from "./App/Pages/Workout/Workout";
import {AddButton} from "./App/General/AddButton";
import {DeleteButton} from "./App/General/DeleteButton/DeleteButton";

function App() {
    const [counts, setCounts] = useState([]);

    const getCounts = () => {
        let countIds = localStorage.getItem('counts');
        countIds = countIds ? JSON.parse(countIds) : [];
        firebaseGet(['counts'])
            .then(val => {
                if (!val) return;
                const thing = Object.values(val)
                    .filter(c => countIds.includes(c.id))
                setCounts(thing);
            })
    }

    useEffectOnce(getCounts);

    const removeCount = (countId) => {
        console.log('boop');
        localStorage.setItem('counts',
            JSON.stringify(
                JSON.parse(localStorage.getItem('counts'))
                    .filter(c => c !== countId)
            )
        );
        getCounts();
    };

    const renderDeleteButton = (count) => {
        if (!count.id) return <div/>;
        const prompt = `Fjerne telletingen "${count.name}"?`;
        return (
            <DeleteButton
                clear
                prompt={prompt}
                onConfirm={() => removeCount(count.id)}
            />
        );
    }

    const renderLink = (count, url) => (
        <Fragment key={count.name}>
            <Link
                className={'rounded thing'}
                to={url}
            >
                {count.name}
            </Link>
            <div className="buttonWrapper">
                {renderDeleteButton(count)}
            </div>
        </Fragment>
    );

    const renderMenu = () => (
        <Menu
            bgColor={'var(--secondary)'}
            menuBgColor={'var(--secondary)'}
        >
            <div className="links">
                {renderLink({name: 'Trening'}, '/')}
                {counts.map(count => renderLink(count, `/counts/${count.id}`))}
                <div className="buttonWrapper">
                    <Link to={'/new-count'}>
                        <AddButton/>
                    </Link>
                </div>
            </div>
        </Menu>
    );

    const p = 0.02;
    const reality = Math.random();
    const blue_moon = p > reality;

    return (
        <BrowserRouter>
            <div className={`App${blue_moon ? ' blue_moon' : ''}`}>
                <div className={`app__content`}>
                    {renderMenu()}
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={() => (<Workout/>)}
                        />
                        <Route
                            exact
                            path="/new-count"
                            render={(router) => (
                                <NewCount
                                    router={router}
                                    onChange={getCounts}
                                />
                            )}
                        />
                        <Route
                            path="/counts/:id/graph"
                            render={(router) => (
                                <Summary
                                    router={router}
                                    onDelete={getCounts}
                            />
                            )}
                        /><Route
                            path="/counts/:id"
                            render={(router) => (
                                <Count
                                    router={router}
                                    onDelete={getCounts}
                            />
                            )}
                        />
                        <Route
                            path="/"
                            render={() => (<FourOhFour/>)}
                        />
                    </Switch>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
