import React, {Fragment} from 'react';
import {monthNames} from "./Summary";

export const Graph = ({years}) => {
    if (!years) return null;
    const months = [];

    let max = 0;

    for (let i = 0; i < 12; i++) {
        months.push({
            label: monthNames[i],
            years: years.map(y => {
                const quantity = y.months[i].quantity;
                max = Math.max(quantity, max);
                return ({
                    label: y.label,
                    quantity
                })
            })
        });
    }
    // console.log('months', months);
    // console.log('years', years);

    // const colors = [
    //     'hotpink',
    //     'dodgerblue',
    //     'darkorange',
    //     'springgreen',
    //     'mediumpurple',
    //     'greenyellow',
    // ]

    const colors = [
        '#990000',
        '#b45f06',
        '#bf9000',
        '#38761d',
        '#134f5c',
    ];

    const getColor = (i) => {
        let safeIndex = i;
        while (safeIndex > colors.length - 1) {
            safeIndex -= colors.length;
        }
        return colors[safeIndex];
    }

    const renderBar = (val, backgroundColor) => {
        const percent = (val / max) * 100;
        return (
            <div className={`bar ${val === 0 ? 'empty' : ''}`}>
                <div className="label">{val}</div>
                <div
                    className="value"
                    style={{
                        width: `${percent}%`,
                        backgroundColor
                    }}
                />
                <div className="spacer"/>
            </div>
        )
    };

    const renderYear = (year, i) => (
        <Fragment key={year.label}>
            <div className="yearLabel">{year.label}</div>
            {renderBar(year.quantity, getColor(i))}
        </Fragment>
    )

    const renderMonth = (month) => (
        <Fragment key={month.label}>
            <div className="monthLabel">{month.label}</div>
            <div/>
            {month.years.map(renderYear)}
        </Fragment>
    );

    return (
        <div className="graph">
            <div className="scrollFade top"/>
            <div className="scrollContainer">
                {months.map(renderMonth)}
            </div>
            <div className="scrollFade bottom"/>
        </div>
    );
}