import React, {useEffect, useState} from "react";
import {firebaseGet} from "../../FireBase/firebase";
import {ReactComponent as Edit} from '../../../resources/edit_note.svg';
import {Link} from "react-router-dom";
import {Graph} from "./Graph";
import {Loader} from "../../General/Loader/Loader";

export const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

const handleData = (dataMap) => {
    const data = Object.values(dataMap);
    const months = [];

    const getMonth = (monthString, year, fullYearArg) => {
        const fullYear = fullYearArg ?? parseInt(`20${year}`);
        const id = [monthString, fullYear].join('_');
        const index = monthNames.findIndex(m => m === monthString);
        let month = months.find(m => m.id === id);

        if (!month) {
            month = {
                id,
                year: fullYear,
                label: monthString,
                quantity: 0,
                index
            }
            months.push(month);
        }
        return month;
    }

    data.forEach((d) => {
        const [monthString, year] = d.id.split('_').slice(1, 3);
        getMonth(monthString, year).quantity += d.quantity;
    });

    const years = [];
    const getYear = (label) => {
        let year = years.find(y => y.label === label);
        if (!year) {
            year = {
                label,
                quantity: 0,
                months: []
            }
            years.push(year)
        }
        return year;
    }

    months.forEach(m => {
        const year = getYear(m.year);
        year.months.push(m);
        year.quantity += m.quantity;
    });

    years.sort((y1, y2) => y1.label - y2.label);
    years.forEach(year => {
        monthNames
            .filter((mn) =>
                !year.months.some(m => m.label === mn)
            ).forEach(monthString => {
            year.months.push(getMonth(
                monthString,
                undefined,
                year.label
            ))
        });
        year.months.sort((m1, m2) => m1.index - m2.index);
    })

    return years;
}

export const Summary = ({router}) => {
    const [count, setCount] = useState();
    const [years, setYears] = useState();
    const id = router.match.params.id;

    useEffect(() => {
        firebaseGet(['counts', id]).then(c => setCount(c));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!count) return;
        setYears(handleData(count.data));
    }, [count])

    return count ? (
        <div className="page summary">
            <Link to={`/counts/${id}`}>
                <div className="thing closeButton"><Edit/></div>
            </Link>
            <h1>{count.name}</h1>
            <div className="graphWrapper">
                <Graph years={years}/>
            </div>
        </div>
    ) : <Loader/>;
}